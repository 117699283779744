<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="600px">
            <div v-if="info.money == 0">
                <h2 class="center color-green" slot="title">{{ $t('客服微信') }}</h2>
                <div class="center">
                    <el-image style="width:400px;" :src="$host + $store.state.setting.kf_qrcode"></el-image>
                </div>

            </div>
            <div v-else>
                <h2 slot="title" class="center  color-green">{{ info.title.replace('/', '') }}</h2>
                <div class="padding flex flex-h-center margin-t">
                    <el-image :src="src"></el-image>
                    <div style="margin-left:30px;">
                        <h2 style="color:#E6A23C;font-size:30px">￥{{ info.money }}</h2>
                        <div class="st1 margin-t">{{ $t('打开手机微信') }}
                        </div>
                        <div class="st1">{{ $t('扫一扫完成支付') }}
                        </div>

                        <div class="margin-lt st">{{ $t('到期不会自动续费') }}!</div>
                    </div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "payDialog",
    data() {
        return {
            info: { title: "", money: 0 },
            dialogVisible: false,
            src: '',
            out_trade_no: ''
        };
    },
    props: {
    },
    methods: {
        show(index, type) {

            this.info = this.$store.getters.getVips()[index];

            if (this.info.money == 0) {
                this.dialogVisible = true;
            } else {

                if (type == 1) {
                    let url = this.$host + "/index.php/vip/pay?token=" + localStorage.getItem("token") + "&tag=web&id=" + this.info.id + '&mode=' + this.$store.state.mode
                    window.open(url)
                    this.$confirm(this.$t("确认支付成功") + "?", this.$t('支付结果'), {
                        closeOnClickModal: false,
                        confirmButtonText: this.$t('支付成功'),
                        confirmButtonClass: "confirm-btn",
                        cancelButtonText: this.$t('支付失败'),
                        type: 'warning'
                    }).then(() => {
                        location.reload()
                    }).catch(() => {

                    });
                } else if (type == 2) {
                    this.$http.post("/api/vip/qrcode", { id: this.info.id }).then((res) => {
                        this.src = res.src;
                        this.out_trade_no = res.out_trade_no;
                        this.dialogVisible = true;
                        this.query();
                    })
                } else if (type == 3) {
                    this.$http.post("/api/vip/bank", { id: this.info.id }).then((url) => {
                        window.open(url)
                        this.$confirm(this.$t("确认支付成功") + "?", this.$t('支付结果'), {
                            closeOnClickModal: false,
                            confirmButtonText: this.$t('支付成功'),
                            confirmButtonClass: "confirm-btn",
                            cancelButtonText: this.$t('支付失败'),
                            type: 'warning'
                        }).then(() => {
                            location.reload()
                        }).catch(() => {

                        });
                    })
                }

            }

        },

        query() {
            if (!this.dialogVisible) return;
            this.$http.post("/api/vip/record", { out_trade_no: this.out_trade_no }).then((res) => {
                if (res.id) {
                    this.dialogVisible = false;
                    this.$notify({
                        title: this.$t('开通成功'),
                        message: this.$t('您已成功开通' + this.info.title) + "!",
                        type: 'success',
                        duration: 0
                    });
                    this.$store.dispatch("fetch_user");
                } else {
                    setTimeout(() => {
                        this.query();
                    }, 2000);
                }
            })
        }
    },
};
</script>
<style scoped></style>
